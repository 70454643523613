import React from 'react';
import clsx from 'clsx';
import AniLink from "gatsby-plugin-transition-link/AniLink";

const MenuTransition = (props) => {
  const {to, item, direction} = props;
  return (
  <AniLink
      className={clsx("menu-item")} 
      cover 
      direction={direction} 
      top="entry"
      to={to}
      bg="black"
    >{item}
    </AniLink>
  );
};

const Menu = (props) => {
  const { isMenuOpen } = props;
  return (
    <ul className={
      clsx("menu",
      isMenuOpen && "menu-open"
    )}>
      <li><MenuTransition to="/" item="Projets" direction="left" /></li>
      <li><MenuTransition to="/about" item="À propos" direction="up"/></li>
      <li><MenuTransition to="/contact" item="Contact" direction="down"/></li>
      <li><MenuTransition to="/mentions" item="Mentions" direction="right"/></li>
    </ul>      
  );
};

export default Menu;
