import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from "@reach/router"
import favicon from '../assets/images/favicon.png';

const SEO = ({ title, description, lang, className, pageName }) => {

  const { pathname } = useLocation();
  const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        author
      }
    }
  }
`
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    author,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
    autor: author,
  };

return (
  <Helmet
    bodyAttributes={{ class: className}}
    meta= {[
      {charSet:"utf-8"}
    ]}
    htmlAttributes={{ lang }}
    title={`${seo.title}${pageName}`}
    link={[
      { rel:"icon",
        type:"image/png", href:`${favicon}`, 
        sizes:"32x32"
      },
    ]}
  >
    <meta name="description" content={seo.description} />
    {seo.url && <meta property="og:url" content={seo.url} />}
    {seo.title && <meta property="og:title" content={seo.title} />}
    {seo.author && <meta property="og:author" content={seo.author} />}
    {seo.description && (
      <meta property="og:description" content={seo.description} />
    )}
  </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  lang: PropTypes.string,
  pageName: PropTypes.string,
}
SEO.defaultProps = {
  title: null,
  description: null,
  author: null,
  lang: 'fr',
  pageName: 'projects',

}
export default SEO;