import React from 'react';

const MenuButton = (props) => {
  const { isMenuOpen, setIsMenuOpen, content } = props;
  return (
    <button 
      className='menu-button'
      onClick={() => setIsMenuOpen(!isMenuOpen)}
    >
      {content}
    </button>
  )
}

export default MenuButton;