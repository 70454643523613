import React from 'react';
import { Link } from 'gatsby';
import logo from '../assets/images/2020-MAMA-portfolio-logo.svg';
import MenuButton from './MenuButton';

const Header = (props) => {
  const { isMenuOpen } = props;
  return (
    <>
    <header className="header">
      <Link to="/" className='home-button'><img src={logo} alt='logo' className='header-image'/></Link>
      <MenuButton
        {...props}
        content={<h2>{isMenuOpen ? "Fermer" : "Menu"}</h2>}
      />
    </header>
    </>
  );
};

export default Header;