import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Menu from './Menu';
import ContactButton from './ContactButton';
import SEO from './SEO';
import 'assets/stylesheets/application.scss';

var slugify = require('slugify');

const Layout = ({ children, pageName }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let className = '';

  if (pageName) {
    className = `${className} page-${pageName}`;
  }

  return (
        <>
          <SEO pageName={pageName}/>
          <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
          <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
          <main className={`main-${slugify(pageName, {lower: true})}`}>
            <div className="column">
              <div className="signature"/>
              {pageName !== "Contact" && <ContactButton />}
            </div>
            {children}
          </main>
        </>
  );

};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;